var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"danger"},on:{"click":_vm.nuevoDolly}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nuevo Dolly ")],1)],1)]),_c('v-card-title',[_vm._v(" Dollys "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"locale":"es","search":_vm.search,"headers":_vm.headers,"items":_vm.lista_dollys,"item-key":"iddolly","show-expand":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.editdolly(item.iddolly)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.eliminardolly(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return undefined}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":7}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Tarjeta de Circulación")]),_c('th',[_vm._v("Póliza")]),_c('th',[_vm._v("Factura")])])]),_c('tbody',[_c('tr',[_c('td',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.tarjeta_circulacion)}}},[_vm._v(" "+_vm._s(item.tarjeta_circulacion))])]),_c('td',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.seguro_vehiculo)}}},[_vm._v(" "+_vm._s(item.seguro_vehiculo))])]),_c('td',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ver_imagen(item.factura)}}},[_vm._v(" "+_vm._s(item.factura))])])])])]},proxy:true}],null,true)})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }